<template>
  <div>
    <ion-grid
      class="ion-no-padding-start ion-no-padding-end"
      v-for="(item, key) in orders || []"
      :key="item.id"
    >
      <div class="ion-padding-start ion-padding-end no-padding" @click="viewOrderDetail(item)">
        <ion-row style="margin-top: 4px">
          <ion-col size="12">
            <div class="fs-3 fw-bold text-center">
              {{ item.supplier_name }}
            </div>
          </ion-col>
        </ion-row>

        <ion-row class="mt-10">
          <ion-col size="12" class="pt-0 pb-0 fs-3 fw-bold text-center">
            <ion-text>{{ item.orderItems[0].customer.name }}</ion-text>
          </ion-col>
          <ion-col size="12" class="pt-0 pb-0 my-1 fs-2 fw-500 text-center text-primary mt-1">
            <ion-text>{{ item.orderItems[0].customer.account_number }}</ion-text>
          </ion-col>
          <ion-col size="12" class="pt-0 pb-0">
            <div class="medium fs-12px d-flex flex-row align-center">
              [{{ item?.CreatedBy?.first_name }}&nbsp;{{ item?.CreatedBy?.last_name }}] &nbsp;{{
                formatTime(item.updated_at, 3)
              }}
            </div>
          </ion-col>
        </ion-row>

        <div
          v-if="isShowDeliveryStatus(item.delivery_status)"
          class="my-2 delivery-status d-flex flex-row align-center justify-space-between"
        >
          <div class="d-flex flex-row align-center">
            <ion-img src="/assets/images/treedots-truck.svg" />
            <ion-text class="ml-1">{{ setDeliveryStatus(item.delivery_status) }}</ion-text>
          </div>
          <ion-icon :icon="chevronForwardOutline" />
        </div>

        <ion-row class="mt-10">
          <ion-col size="6">
            <ion-text class="text-bold fs-3"
              >{{ !item.is_booking ? $t('orderB2b.order') : $t('booking') }} #{{ item.id }}</ion-text
            >
          </ion-col>
          <ion-col size="6" class="d-flex justify-end">
            <ion-text class="text-bold fs-3" align="right"> {{ item.display_price }}</ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="pre-paid ion-no-padding" size="12">
            <ion-text
              class="un-paid"
              v-if="
                !hasPendingPayment(item) &&
                item.orderStatus.name == ORDER_STATUS_NAME.PROCESSING &&
                !item.is_paid
              "
            >
              {{ $t('un_paid') }}
            </ion-text>
            <ion-text
              class="paid"
              v-if="
                !hasPendingPayment(item) &&
                item.orderStatus.name == ORDER_STATUS_NAME.PROCESSING &&
                item.is_paid
              "
            >
              {{ $t('paid') }}
            </ion-text>
            <ion-text class="pending-payment" v-if="hasPendingPayment(item)">
              {{ $t('pending_payment') }}
            </ion-text>
          </ion-col>
        </ion-row>

        <ion-row class="align-center">
          <ion-col size="10">
            <ion-text class="medium fs-12px">
              <ion-label class="d-flex">
                {{ $t('orderB2b.created') }}: {{ formatTime(item.created_at, 1) }}
              </ion-label>
              <ion-label class="d-flex" v-if="!item.is_booking">
                {{ $t('orderB2b.delivery') }}: {{ formatTime(item.delivery_date, 2) }}
                <span v-if="item.delivery_time && item.delivery_time !== 'null'"
                  >&nbsp;{{ item.delivery_time }}</span
                >
              </ion-label>
            </ion-text>
          </ion-col>

          <ion-col size="2" class="d-flex justify-end">
            <ion-text class="medium fs-12px" align="right"
              >{{ item.orderItems.length }}
              {{ item.orderItems.length > 1 ? $t('orderB2b.items') : $t('orderB2b.item') }}</ion-text
            >
          </ion-col>
        </ion-row>

        <ion-row class="align-center">
          <ion-col size="6">
            <!-- Order status cheap  -->
            <div v-if="isOrderBlocked(item)" class="bg-status bg-red">
              <div class="mr-1 dot red" />
              <ion-text class="status-text text-red">{{ $t('on_hold') }}</ion-text>
            </div>

            <NewOrderStatusCheaps v-else :itemInfo="item" :isBookingOrder="item.is_booking" />
          </ion-col>

          <ion-col size="6" align="right">
            <ion-buttons class="justify-end">
              <ion-button
                fill="outline"
                class="border-outline text-capitalize ml-1"
                @click.stop="repeatOrder(item)"
                v-if="showRepeatButton(item)"
              >
                <ion-label class="pl-1 pr-1 fs-12 small-device">{{ $t('orderB2b.repeat') }} </ion-label>
              </ion-button>

              <ion-button
                fill="outline"
                class="border-outline text-capitalize"
                @click.stop="openEditModal(item)"
                v-if="showEditButton(item)"
              >
                <ion-label class="pl-1 pr-1 fs-12 small-device">{{ $t('edit') }} </ion-label>
              </ion-button>
              <ion-button
                fill="outline"
                class="border-outline text-capitalize"
                @click.stop="goToPaymentDetailsPage(item)"
                v-if="showPaymentInstructionButton(item)"
              >
                <ion-label class="pl-1 pr-1 fs-12 small-device"
                  >{{ $t('orderB2b.paymentInstruction') }}
                </ion-label>
              </ion-button>
            </ion-buttons>
          </ion-col>
        </ion-row>

        <ion-row class="align-center" v-if="isOrderBlocked(item)">
          <ion-col size="12">
            <block-order-detail :orderId="item.id" />
          </ion-col>
        </ion-row>

        <ion-button
          @click.stop="setOpenInvoiceAndCreditNote(true, item.orderItems[0].invoice_id, item.id)"
          v-if="
            [
              ORDER_STATUS_NAME.PROCESSED,
              ORDER_STATUS_NAME.UPDATED,
              ORDER_STATUS_NAME.COMPLETED,
              ORDER_STATUS_NAME.COLLECTED,
              ORDER_STATUS_NAME.DELIVERING,
              ORDER_STATUS_NAME.ARRIVED,
              ORDER_STATUS_NAME.DELIVERED
            ].includes(item.orderStatus.name) && item.orderItems[0]?.invoice_id
          "
          class="invoice-btn mt-2"
          color="primary"
          expand="block"
          >{{ item.credit_note > 0 ? $t('invoice_cn.invoice_cn') : $t('invoice_cn.invoice_s') }}</ion-button
        >
        <ion-row v-if="getCancelledBy(key)">
          <ion-col size="12">
            <div class="message-bottom px-2 py-2 fs-13 fw-600">
              <ion-text>
                <div>{{ $t('cancelled_by') }} {{ getCancelledBy(key) }}</div>
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
      </div>
      <ion-item-divider class="d-flex mb-10 mt-10" style="min-height: 1px" />
    </ion-grid>

    <ion-grid style="height: 100%" v-if="orders.length === 0">
      <ion-row class="justify-center align-center" style="height: 100%">
        <div class="text-center">
          <ion-img
            style="width: 110px; height: 110px; margin: 0 auto"
            src="/assets/images/empty-box.png"
          ></ion-img>
          <div class="fs-4 mt-4 mb-2 fw-bold">{{ $t('sorry') }}</div>
          <div class="fs-3">{{ $t('no_transaction_made') }}</div>
          <ion-button class="mt-3 text-capitalize" color="primary" @click="goHome()">
            <span class="ml-1">{{ $t('start_purchasing_today') }}</span>
          </ion-button>
        </div>
      </ion-row>
    </ion-grid>

    <ion-modal
      ref="modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.5, 0.75, 1]"
      mode="ios"
      css-class="default-bottom-sheet-modal"
      :is-open="isOpenEditModalRef"
      @didDismiss="setOpenEditModal(false)"
    >
      <update-order-modal :orderDetails="orderDetails" @update-data="updateData"></update-order-modal>
    </ion-modal>
    <ion-modal
      mode="ios"
      :is-open="isOpenInvoiceCreditNotes"
      @didDismiss="setOpenInvoiceAndCreditNote(false, null, null)"
    >
      <invoice-and-credit-note-merchant
        v-if="isMerchantUser"
        :detailOrderId="orderDetailId"
        :invoiceId="invoiceId"
        :currencySymbol="currencySymbol"
        @close-page="setOpenInvoiceAndCreditNote(false, null, null)"
      />
      <invoice-and-credit-note-sale
        v-if="!isMerchantUser"
        :detailOrderId="orderDetailId"
        :invoiceId="invoiceId"
        :currencySymbol="currencySymbol"
        @close-page="setOpenInvoiceAndCreditNote(false, null, null)"
      />
    </ion-modal>
    <ion-loading
      :is-open="isOpenLoadingref"
      @didDismiss="setOpenLoading(false)"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
    >
    </ion-loading>
  </div>
</template>

<script>
import InvoiceAndCreditNoteMerchant from '@/modules/b2b/views/order/components/InvoiceAndCreditNoteList';
import InvoiceAndCreditNoteSale from '@/modules/sale/views/order/components/InvoiceAndCreditNoteList';
import BlockOrderDetail from '@/modules/shared/components/order/BlockOrderDetail';
import {
  ACTION_BY_MERCHANT,
  DELIVERY_STATUS,
  INVOICE_PAYMENT_TYPE,
  MERCHANT_APP_NAME,
  ORDER_BLOCK_STATUS,
  ORDER_STATUS_NAME,
  PAYMENT_UNSUCCESSFUL
} from '@/modules/shared/constants';
import { getOrderDetails } from '@/modules/shared/services/graphql';
import { useLoading } from '@/modules/shared/utils/';
import { setBadgeNumber } from '@/modules/shared/utils/badge';
import { executeApolloClient } from '@/services/shared/apollo-client';
import { repeatOrderItems } from '@/services/shared/graphql';
import { PAYMENT_TRANSACTION_STATUS } from '@/services/shared/helper/constants';
import { useDateFormatter } from '@/usecases/global';
import { priceFormatter } from '@/utils/';
import { alertController, popoverController, toastController } from '@ionic/vue';
import dayjs from 'dayjs';
import {
  alertCircleOutline,
  carSportOutline,
  checkmarkCircleOutline,
  checkmarkDoneOutline,
  chevronForwardOutline,
  closeOutline,
  createOutline,
  flashOutline,
  helpOutline,
  navigateCircleOutline,
  repeatOutline
} from 'ionicons/icons';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import NewOrderStatusCheaps from './NewOrderStatusCheaps';
import UpdateOrderModal from './UpdateOrderModal';
export default defineComponent({
  name: 'purchase-item',
  emits: ['load-more', 'reload-order'],
  components: {
    UpdateOrderModal,
    NewOrderStatusCheaps,
    InvoiceAndCreditNoteMerchant,
    InvoiceAndCreditNoteSale,
    BlockOrderDetail
  },
  props: {
    orders: {
      type: Object,
      default: new Array([])
    },
    isDisableLoadMore: {
      type: Boolean,
      default: false
    },
    isEnableSoftBlockOrder: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const storage = inject('$storage');
    const router = useRouter();
    const store = useStore();
    dayjs.locale();
    const { formatDateTime, formatDateMonthYear } = useDateFormatter();
    const isMerchantUser = router.currentRoute.value.fullPath.indexOf('/b2b/order') > -1;
    const rootPath = isMerchantUser ? 'b2b' : 'sale';
    const { t } = useI18n();

    const user = ref(null);
    const selectCompany = ref(null);
    const NoLoadItems = ref(false);
    const isOpenEditModalRef = ref(false);
    const setOpenEditModal = (state) => (isOpenEditModalRef.value = state);
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    const { showLoading } = useLoading();
    const event = ref();
    const orderDetails = ref({});
    const orderDetailId = ref(null);
    const isReload = ref(false);
    const isOpenInvoiceCreditNotes = ref(false);
    const invoiceAndCreditNoteData = ref({});
    const invoiceId = ref(null);
    const currencySymbol = ref(null);

    const openToast = async (message, color = 'danger', position = 'top') => {
      const toast = await toastController.create({
        message,
        position,
        color,
        duration: 3000,
        icon: alertCircleOutline
      });
      return toast.present();
    };

    const viewOrderDetail = (item) => {
      router.push({ path: `/${rootPath}/order/purchase/order-detail/${item.id}` });
    };

    const loadMore = (e) => {
      emit('load-more', e);
    };

    const formatTime = (params, id) => {
      return id === 1
        ? formatDateTime(params)
        : id === 2
        ? formatDateMonthYear(params)
        : dayjs(params).format('YYYY-MM-DD HH:mm:ss');
    };
    const repeatOrder = async (item) => {
      try {
        setOpenLoading(true);
        const res = await executeApolloClient(repeatOrderItems, {
          customerId: selectCompany.value.id,
          orderId: item.id
        });
        if (res.repeatOrderItems.unAvailableSkuIds.length > 0) {
          // show toat info that any items is not avialable or the product items discoverability is cannot view
          openToast(t('items_not_available'));
        }
        if (res.repeatOrderItems.success) {
          router.push(`/${rootPath}/cart`);
        }
        setBadgeNumber(store, selectCompany.value.id, user.value.tenant.id);
      } catch (error) {
        const alert = await alertController.create({
          message: error.message,
          buttons: [
            {
              text: 'CLOSE'
            }
          ]
        });
        await alert.present();
      } finally {
        setOpenLoading(false);
      }
    };
    const openEditModal = async (item) => {
      try {
        setOpenLoading(true);
        if (!item.id) {
          throw new Error('Order id is invalid');
        }
        const res = await executeApolloClient(getOrderDetails, {
          orderId: item.id
        });
        orderDetails.value = res.getOrderDetails;
        setOpenEditModal(true);
      } catch (error) {
        openToast(error.message);
      } finally {
        setOpenLoading(false);
      }
    };
    const updateData = async () => {
      await setOpenEditModal(false);
      await emit('reload-order');
    };
    const goHome = () => {
      router.push(`/${rootPath}/main/home`);
    };
    const goToPaymentDetailsPage = (item) => {
      router.push(`/${rootPath}/payment-transaction/${item.id}`);
    };
    const setOpenInvoiceAndCreditNote = (val, invoice_id, orderId) => {
      orderDetailId.value = orderId;
      invoiceId.value = invoice_id;
      isOpenInvoiceCreditNotes.value = val;
    };
    const isOrderBlocked = (item) => {
      return (
        item.orderStatus.name === ORDER_STATUS_NAME.PROCESSING &&
        props.isEnableSoftBlockOrder &&
        [ORDER_BLOCK_STATUS.LIMIT_BLOCKED, ORDER_BLOCK_STATUS.UNCERTAINTY_BLOCKED].includes(item.block_status)
      );
    };

    const hasPendingPayment = (item) => {
      return (
        item?.paymentTransactionItems &&
        item?.paymentTransactionItems.length > 0 &&
        item?.paymentTransactionItems[0].paymentTransaction.status === PAYMENT_TRANSACTION_STATUS.PENDING
      );
    };

    const showEditButton = (item) => {
      const { orderStatus, can_update_processed_order } = item || {};

      const isProcessing = orderStatus?.name === ORDER_STATUS_NAME.PROCESSING;
      const isProcessedAndUpdatable =
        orderStatus?.name === ORDER_STATUS_NAME.PROCESSED && can_update_processed_order;

      return (isProcessing || isProcessedAndUpdatable) && !hasPendingPayment(item);
    };

    const setDeliveryStatus = (status) => {
      let deliveryStatus =
        status === DELIVERY_STATUS.IN_PROGRESS || status === DELIVERY_STATUS.STARTED
          ? t('delivery_started')
          : status === DELIVERY_STATUS.FAILED
          ? t('failed_delivery')
          : status === DELIVERY_STATUS.COMPLETED
          ? t('delivery_successful')
          : '';
      return deliveryStatus;
    };

    const isShowDeliveryStatus = (status) => {
      const deliveryStatusToShow = [
        DELIVERY_STATUS.IN_PROGRESS,
        DELIVERY_STATUS.STARTED,
        DELIVERY_STATUS.FAILED,
        DELIVERY_STATUS.COMPLETED
      ];
      return deliveryStatusToShow.includes(status);
    };

    const showRepeatButton = (item) => item?.orderStatus.name !== ORDER_STATUS_NAME.PROCESSING;

    const showPaymentInstructionButton = (item) =>
      item?.paymentTransactionItems &&
      item?.paymentTransactionItems.length > 0 &&
      user.value?.id === item?.paymentTransactionItems[0].paymentTransaction.created_by &&
      !hasPendingPayment(item) &&
      item.payment_type !== INVOICE_PAYMENT_TYPE.CASH_PAYMENT_COLLECTION &&
      item.payment_type !== INVOICE_PAYMENT_TYPE.CREDIT_CARD;

    const isShowName = (data) => {
      let str1 = data?.first_name?.replace(/\s/g, '');
      let str2 = data?.last_name?.replace(/\s/g, '');
      if (str1?.length + str2?.length < 10) {
        return true;
      } else {
        return false;
      }
    };
    const showIconStatus = (statusName) => {
      if (statusName.includes(ORDER_STATUS_NAME.PROCESSING)) return repeatOutline;
      else if (statusName.includes(ORDER_STATUS_NAME.PROCESSED)) return repeatOutline;
      else if (statusName.includes(ORDER_STATUS_NAME.UPDATED)) return createOutline;
      else if (statusName.includes(ORDER_STATUS_NAME.COMPLETED)) return checkmarkCircleOutline;
      else if (statusName.includes(ORDER_STATUS_NAME.COLLECTED)) return flashOutline;
      else if (statusName.includes(ORDER_STATUS_NAME.DELIVERING)) return carSportOutline;
      else if (statusName.includes(ORDER_STATUS_NAME.ARRIVED)) return navigateCircleOutline;
      else if (statusName.includes(ORDER_STATUS_NAME.DELIVERED)) return checkmarkDoneOutline;
      else return closeOutline;
    };
    const getCancelledBy = (key) => {
      const item = props.orders[key];
      const status = item?.orderStatus.name;
      let cancelledBy = '';

      if (status === ORDER_STATUS_NAME.CANCELLED && item.app_name !== MERCHANT_APP_NAME) {
        cancelledBy = 'Supplier';
      } else if (
        status === ORDER_STATUS_NAME.CANCELLED &&
        item.app_name === MERCHANT_APP_NAME &&
        (item?.orderLogs?.[0]?.description?.logs?.[0]?.reason === ACTION_BY_MERCHANT ||
          item?.reason === PAYMENT_UNSUCCESSFUL)
      ) {
        cancelledBy = item.app_name;
      } else if (status === ORDER_STATUS_NAME.CANCELLED && item.app_name === MERCHANT_APP_NAME) {
        cancelledBy = 'Supplier';
      }
      return cancelledBy;
    };

    onMounted(async () => {
      user.value = await storage.getUser();
      selectCompany.value = await storage.getSelectedCompany();
      currencySymbol.value = user.value ? user.value.currency_symbol : '';
    });

    return {
      helpOutline,
      repeatOutline,
      closeOutline,
      createOutline,
      alertCircleOutline,
      checkmarkCircleOutline,
      flashOutline,
      carSportOutline,
      navigateCircleOutline,
      checkmarkDoneOutline,
      chevronForwardOutline,
      user,
      selectCompany,
      NoLoadItems,
      isOpenEditModalRef,
      setOpenEditModal,
      openToast,
      setOpenLoading,
      isOpenLoadingref,
      orderDetails,
      showLoading,
      popoverController,
      currencySymbol,
      event,
      priceFormatter,
      isReload,
      isOpenInvoiceCreditNotes,
      invoiceAndCreditNoteData,
      invoiceId,
      orderDetailId,
      ORDER_STATUS_NAME,
      INVOICE_PAYMENT_TYPE,
      ORDER_BLOCK_STATUS,
      DELIVERY_STATUS,
      viewOrderDetail,
      loadMore,
      formatTime,
      repeatOrder,
      openEditModal,
      updateData,
      goHome,
      goToPaymentDetailsPage,
      setOpenInvoiceAndCreditNote,
      isOrderBlocked,
      showEditButton,
      showRepeatButton,
      hasPendingPayment,
      showPaymentInstructionButton,
      isShowName,
      showIconStatus,
      getCancelledBy,
      isMerchantUser,
      setDeliveryStatus,
      isShowDeliveryStatus
    };
  }
});
</script>
<style src="./style.scss" lang="scss" scoped></style>
<style src="../../order-detail/style.scss" lang="scss" scoped></style>

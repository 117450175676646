<template>
  <ion-page>
    <ion-content :fullscreen="true" id="hidden-scroll">
      <ion-header translucent>
        <ion-toolbar>
          <ion-row class="py-2">
            <ion-col class="fs-3" style="font-size: 16px; font-weight: 500">
              {{ $t('orderB2b.filter') }}
            </ion-col>
            <ion-col class="ion-text-right primary fs-2 fw-700 reset" @click="resetSelections()">
              {{ $t('orderB2b.reset') }}
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </ion-header>

      <form>
        <ion-list class="pt-3 pb-3 px-3">
          <ion-item
            lines="none"
            :detail="false"
            id="open-branch-filter"
            class="item-line ion-no-padding"
            @click="setOpen(true)"
          >
            <div slot="start">
              <ion-label class="fw-500 mb-1">{{ $t('orderB2b.companies') }} </ion-label>
              <ion-label class="w-100" id="companyName"
                ><span>{{ displayCompanyName }}</span></ion-label
              >
            </div>
            <ion-icon class="chevdown" slot="end" id="disable-click" :icon="chevronDownOutline" />
          </ion-item>
          <ion-item
            @click="setOpenOrderStatus(true)"
            lines="none"
            :detail="false"
            class="item-line ion-no-padding mt-2"
          >
            <div slot="start">
              <ion-label class="fw-500 mb-1">{{ $t('order_status') }} </ion-label>
              <ion-label>{{
                selectedOrderStatusIds.length === countOrderStatus ? $t('all') : displaySavedOrderStatuses
              }}</ion-label>
            </div>
            <ion-icon class="chevdown" slot="end" id="disable-click" :icon="chevronDownOutline" />
          </ion-item>

          <ion-item
            lines="none"
            :detail="false"
            id="open-branch-filter"
            class="item-line ion-no-padding mt-1"
            @click="setOpenFilterByStatus(true)"
          >
            <div slot="start">
              <ion-label class="fw-500 mb-1">{{ $t('fulfillment_status') }} </ion-label>
              <ion-label
                ><span>{{
                  selectedStatusIds.length === 3 ? $t('all') : displaySavedStatusNames
                }}</span></ion-label
              >
            </div>
            <ion-icon class="chevdown" slot="end" id="disable-click" :icon="chevronDownOutline" />
          </ion-item>

          <ion-item
            lines="none"
            :detail="false"
            id="open-branch-filter"
            class="item-line ion-no-padding mt-2"
            :button="true"
            @click="handleOpenModalDateTime(filterOptionsRef.startDeliveryDate, 'from')"
          >
            <div slot="start">
              <ion-label class="fw-500 mb-1">{{ $t('orderB2b.fulfillmentDateFrom') }}</ion-label>
              <ion-label> {{ formatDate(filterOptionsRef.startDeliveryDate) }}</ion-label>
            </div>
            <ion-icon class="chevdown" slot="end" id="disable-click" :icon="chevronDownOutline" />
          </ion-item>

          <ion-item
            lines="none"
            :detail="false"
            id="open-branch-filter"
            class="item-line ion-no-padding mt-2"
            :button="true"
            @click="handleOpenModalDateTime(filterOptionsRef.endDeliveryDate, 'to')"
            :class="invalidSelectionDate ? 'item-danger ion-no-padding' : 'ion-no-padding'"
          >
            <div slot="start">
              <ion-label class="fw-500 mb-1" :class="invalidSelectionDate ? 'item-danger' : ''">{{
                $t('orderB2b.fulfillmentDateTo')
              }}</ion-label>
              <ion-label>{{ formatDate(filterOptionsRef.endDeliveryDate) }}</ion-label>
            </div>
            <ion-icon class="chevdown" slot="end" id="disable-click" :icon="chevronDownOutline" />
          </ion-item>
          <ion-text color="danger" v-if="invalidSelectionDate">{{ $t('orderB2b.selectDate') }}</ion-text>

          <ion-item
            lines="none"
            :detail="false"
            id="open-branch-filter"
            class="item-line ion-no-padding mt-2"
          >
            <div style="width: 100%">
              <ion-label class="fw-500 mb-1">{{ $t('orderB2b.orderNumber') }}</ion-label>
              <ion-input
                v-model="orderNumber"
                :placeholder="$t('orderB2b.orderNumber')"
                clearInput
                style="width: 100%"
                @ionInput="onOrderNumberInput($event)"
                ref="orderNumberField"
              />
            </div>
          </ion-item>
        </ion-list>
      </form>
    </ion-content>
    <ion-footer>
      <ion-toolbar style="--background: white">
        <ion-row>
          <ion-col>
            <ion-button class="foot-btn-outline" expand="block" fill="outline" @click="$emit('close-page')">
              {{ $t('cancel') }}
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button class="foot-btn" :disabled="invalidSelectionDate" expand="block" @click="applyFilter">
              {{ $t('apply') }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-footer>
    <ion-modal
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="default-bottom-sheet-modal"
      :is-open="isOpenRef"
      @didDismiss="setOpen(false)"
    >
      <SearchCompanyModal
        :customerBuyerIds="filterOptionsRef.customerBuyerIds"
        :customerBuyerNames="filterOptionsRef.customerBuyerNames"
        @closeModal="setOpen(false)"
        @applySelections="applySelectedCompanies"
      />
    </ion-modal>
    <ion-modal
      :initial-breakpoint="1"
      :breakpoints="[0, 0.5, 1]"
      mode="ios"
      css-class="modal-instruction"
      :is-open="isOpenOrderStatus"
      @didDismiss="setOpenOrderStatus(false)"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <ion-text slot="start" class="fs-31 fw-bold">{{ $t('order_status') }}</ion-text>
            <ion-icon
              @click="setOpenOrderStatus(false)"
              class="close-modal"
              slot="end"
              :icon="closeOutline"
            ></ion-icon>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-item lines="none" :detail="false" class="item-line py-1">
            <ion-checkbox
              slot="start"
              @click="handleAllOrderStatus($event)"
              ref="checkAllOrderStatusRef"
              label-placement="end"
            ></ion-checkbox>
            <ion-text class="mb-1">{{ $t('all') }}</ion-text>
          </ion-item>
          <ion-item
            lines="none"
            :detail="false"
            class="item-line py-1"
            v-for="status in orderStatuses"
            :key="status.id"
            @click="handleSelectedOrderStatus($event, status)"
          >
            <ion-checkbox
              slot="start"
              :id="'checkbox_' + status.id"
              :checked="selectedOrderStatusIds.includes(status.id) ? true : false"
              label-placement="end"
            ></ion-checkbox>
            <ion-text class="mb-1">{{ status.name }}</ion-text>
          </ion-item>
        </ion-content>
        <ion-footer>
          <ion-toolbar style="--background: white">
            <ion-row>
              <ion-col>
                <ion-button
                  class="foot-btn-outline"
                  expand="block"
                  fill="outline"
                  @click="handleCancelOrderStatus"
                  >{{ $t('cancel') }}</ion-button
                >
              </ion-col>
              <ion-col>
                <ion-button
                  :disabled="selectedOrderStatusIds.length < 1"
                  class="foot-btn"
                  expand="block"
                  @click="setOpenOrderStatus(false)"
                  >{{ $t('apply') }}</ion-button
                >
              </ion-col>
            </ion-row>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>
    <ion-modal
      :initial-breakpoint="1"
      :breakpoints="[0, 0.5, 1]"
      mode="ios"
      css-class="modal-45"
      :is-open="isOpenFulfillmentStatus"
      @didDismiss="setOpenFilterByStatus(false)"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <ion-text slot="start" class="fs-31 fw-bold">{{ $t('delivery_status') }}</ion-text>
            <ion-icon
              @click="setOpenFilterByStatus(false)"
              class="close-modal"
              slot="end"
              :icon="closeOutline"
            ></ion-icon>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-item lines="none" :detail="false" class="item-line py-1">
            <ion-checkbox
              slot="start"
              @click="handleAllFulfillmentStatus($event)"
              ref="checkAllRef"
              label-placement="end"
            ></ion-checkbox>
            <ion-text class="mb-1">{{ $t('all') }}</ion-text>
          </ion-item>
          <ion-item
            lines="none"
            :detail="false"
            class="item-line py-1"
            v-for="option in fulfilmentStatusOptions"
            :key="option.id"
            @click="handleSelectedStatus($event, option)"
          >
            <ion-checkbox
              slot="start"
              :id="'checkbox_' + option.id"
              :checked="selectedStatusIds.includes(option.id) ? true : false"
              label-placement="end"
            ></ion-checkbox>
            <ion-text class="mb-1">{{ option.name }}</ion-text>
          </ion-item>
        </ion-content>
        <ion-footer>
          <ion-toolbar style="--background: white">
            <ion-row>
              <ion-col>
                <ion-button
                  class="foot-btn-outline"
                  expand="block"
                  fill="outline"
                  @click="handleCancelFulfillmentStatus"
                  >{{ $t('cancel') }}</ion-button
                >
              </ion-col>
              <ion-col>
                <ion-button
                  :disabled="selectedStatusIds.length < 1"
                  class="foot-btn"
                  expand="block"
                  @click="setOpenFilterByStatus(false)"
                  >{{ $t('apply') }}</ion-button
                >
              </ion-col>
            </ion-row>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>
    <ModalDateTime
      v-if="isOpenModalDateTime"
      :initialization_time="initializationTime"
      @handleSendTimeChange="handleSendTimeChange"
    />
  </ion-page>
</template>

<script>
import ModalDateTime from '@/components/DateTime/index.vue';
import { ORDER_BLOCK_STATUS, ORDER_STATUS_IDS } from '@/modules/shared/constants';
import { useDateFormatter } from '@/usecases/global';
import { Keyboard } from '@capacitor/keyboard';
import dayjs from 'dayjs';
import { chevronDownOutline, closeCircle, closeOutline } from 'ionicons/icons';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import SearchCompanyModal from '../../../company/SearchCompanyModal.vue';
export default {
  name: 'filter-modal',
  emits: ['close-page', 'apply-filter'],
  components: {
    ModalDateTime,
    SearchCompanyModal
  },
  props: {
    fakeOnHoldStatus: {
      type: Number,
      default: 9999
    },
    orderStatusesData: {
      type: Object,
      default: new Array([])
    },
    isEnableSoftBlockOrder: {
      type: Boolean,
      default: false
    },
    filterOptions: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { formatDateMonthYear, isValidDate } = useDateFormatter();
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);
    const checkAllOrderStatusRef = ref(null);
    const selectedOrderStatusIds = ref([...props.filterOptions.statusIds]);
    const selectedOrderStatusNames = ref([...props.filterOptions.orderStatusNames]);
    const isOpenOrderStatus = ref(false);
    const orderNumber = ref(props.filterOptions?.orderNumber);
    let processingStatusId = ORDER_STATUS_IDS.PROCESSING;
    const orderStatuses = [...props.orderStatusesData];
    const isEnableSoftBlockOrder = !!props.isEnableSoftBlockOrder;
    const orderNumberField = ref();
    if (isEnableSoftBlockOrder) {
      orderStatuses.push({ id: props.fakeOnHoldStatus, name: 'On Hold' });
    }
    const countOrderStatus = ref(orderStatuses.length);
    const setOpenOrderStatus = (state) => {
      isOpenOrderStatus.value = state;
      if (state) {
        setTimeout(() => {
          checkAllOrderStatusRef.value.$el.checked = orderStatuses.every((obj) =>
            selectedOrderStatusIds.value.includes(obj.id)
          );
        }, 300);
      }
    };

    const handleCancelOrderStatus = () => {
      selectedOrderStatusIds.value = [...props.filterOptions.statusIds];
      selectedOrderStatusNames.value = [...props.filterOptions.orderStatusNames];
      setOpenOrderStatus(false);
    };

    const handleSelectedOrderStatus = (e, option) => {
      if (!e.target.checked) {
        selectedOrderStatusIds.value = selectedOrderStatusIds.value.filter((id) => id !== option.id);
        selectedOrderStatusNames.value = selectedOrderStatusNames.value.filter(
          (name) => name !== option.name
        );
      } else {
        selectedOrderStatusIds.value.push(option.id);
        selectedOrderStatusNames.value.push(option.name);
      }
      // update check all check box
      checkAllOrderStatusRef.value.$el.checked = orderStatuses.every((obj) =>
        selectedOrderStatusIds.value.includes(obj.id)
      );
    };

    const handleAllOrderStatus = (e) => {
      if (e.target.checked) {
        orderStatuses.map((option) => {
          if (selectedOrderStatusIds.value.includes(option.id)) {
            selectedOrderStatusIds.value = selectedOrderStatusIds.value.filter((id) => id !== option.id);
            selectedOrderStatusNames.value = selectedOrderStatusNames.value.filter(
              (name) => name !== option.name
            );
          }
        });
      } else {
        orderStatuses.map((option) => {
          if (!selectedOrderStatusIds.value.includes(option.id)) {
            selectedOrderStatusIds.value.push(option.id);
            selectedOrderStatusNames.value.push(option.name);
          }
        });
      }
    };

    const checkAllRef = ref(null);
    const selectedStatusIds = ref([...props.filterOptions.tlTaskStatusIds]);
    const selectedStatusNames = ref([...props.filterOptions.fulfillmentStatusNames]);
    const isOpenFulfillmentStatus = ref(false);
    const setOpenFilterByStatus = (state) => {
      isOpenFulfillmentStatus.value = state;
      if (state) {
        setTimeout(() => {
          checkAllRef.value.$el.checked = fulfilmentStatusOptions.value.every((obj) =>
            selectedStatusIds.value.includes(obj.id)
          );
        }, 300);
      }
    };
    const handleCancelFulfillmentStatus = () => {
      selectedStatusIds.value = [...props.filterOptions.tlTaskStatusIds];
      selectedStatusNames.value = [...props.filterOptions.fulfillmentStatusNames];
      setOpenFilterByStatus(false);
    };

    const handleSelectedStatus = (e, option) => {
      if (!e.target.checked) {
        selectedStatusIds.value = selectedStatusIds.value.filter((id) => id !== option.id);
        selectedStatusNames.value = selectedStatusNames.value.filter((name) => name !== option.name);
      } else {
        selectedStatusIds.value.push(option.id);
        selectedStatusNames.value.push(option.name);
      }
      // update check all check box
      checkAllRef.value.$el.checked = fulfilmentStatusOptions.value.every((obj) =>
        selectedStatusIds.value.includes(obj.id)
      );
    };

    const handleAllFulfillmentStatus = (e) => {
      if (e.target.checked) {
        fulfilmentStatusOptions.value.map((option) => {
          if (selectedStatusIds.value.includes(option.id)) {
            selectedStatusIds.value = selectedStatusIds.value.filter((id) => id !== option.id);
            selectedStatusNames.value = selectedStatusNames.value.filter((name) => name !== option.name);
          }
        });
      } else {
        fulfilmentStatusOptions.value.map((option) => {
          if (!selectedStatusIds.value.includes(option.id)) {
            selectedStatusIds.value.push(option.id);
            selectedStatusNames.value.push(option.name);
          }
        });
      }
    };

    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    const isOpenModalDateTime = ref(false);
    const initializationTime = ref(null);
    const initializationModal = ref(null);
    const filterOptionsRef = reactive({ ...props.filterOptions });
    const invalidSelectionDate = ref(false);
    // init data for order status list
    const initFilterOption = {
      customerBuyerNames: [],
      fulfillmentStatusNames: [],
      orderStatusNames: [],
      startDeliveryDate: null,
      endDeliveryDate: null,
      statusIds: [],
      tlTaskStatusIds: [],
      customerBuyerIds: [],
      isLoadMore: false,
      limit: 5,
      blockStatus: [],
      offset: 0
    };

    const fulfilmentStatusOptions = ref([
      {
        id: 1,
        name: t('delivery_started')
      },
      {
        id: 2,
        name: t('delivery_successful')
      },
      {
        id: 3,
        name: t('failed_delivery')
      }
    ]);

    const updateFilterOptions = (props) => {
      Object.assign(filterOptionsRef, props);
    };

    const displayCompanyName = computed(() => {
      if (!filterOptionsRef || filterOptionsRef.customerBuyerNames.toString().length === 0) {
        return '';
      } else if (filterOptionsRef.customerBuyerNames.toString().length > 32) {
        return filterOptionsRef.customerBuyerNames.toString().slice(0, 32) + '...';
      } else {
        return filterOptionsRef.customerBuyerNames.toString().slice(0, 32);
      }
    });
    const displaySavedOrderStatuses = computed(() => {
      return selectedOrderStatusNames.value
        ? selectedOrderStatusNames.value.toString().length > 32
          ? selectedOrderStatusNames.value.toString().slice(0, 32) + '...'
          : selectedOrderStatusNames.value.join(', ')
        : '';
    });
    const displaySavedStatusNames = computed(() => {
      return selectedStatusNames.value ? selectedStatusNames.value.join(', ') : '';
    });

    const handleOpenModalDateTime = (data, fieldName) => {
      initializationModal.value = fieldName;
      initializationTime.value = data;
      isOpenModalDateTime.value = true;
    };

    const handleSendTimeChange = (e) => {
      initializationModal.value === 'from'
        ? updateFilterOptions({ startDeliveryDate: e })
        : updateFilterOptions({ endDeliveryDate: e });
      isOpenModalDateTime.value = false;

      // validate start time & end time
      filterOptionsRef.startDeliveryDate > filterOptionsRef.endDeliveryDate &&
      filterOptionsRef.startDeliveryDate !== null &&
      filterOptionsRef.endDeliveryDate !== null
        ? (invalidSelectionDate.value = true)
        : (invalidSelectionDate.value = false);
    };

    const resetSelections = async () => {
      selectedStatusIds.value = [];
      selectedStatusNames.value = [];
      selectedOrderStatusIds.value = [];
      selectedOrderStatusNames.value = [];
      orderNumber.value = null;
      Object.assign(filterOptionsRef, initFilterOption);
    };

    const applySelectedCompanies = (options) => {
      Object.assign(filterOptionsRef, options);
      setOpen(false);
    };

    const applyFilter = async () => {
      // handle parameter filter for status on Hold
      const isBlockedIncluded = selectedOrderStatusIds.value.includes(props.fakeOnHoldStatus);
      const isProcessingIncluded = selectedOrderStatusIds.value.includes(processingStatusId);
      let blockStatus = [];
      if (isBlockedIncluded) {
        blockStatus = [ORDER_BLOCK_STATUS.LIMIT_BLOCKED, ORDER_BLOCK_STATUS.UNCERTAINTY_BLOCKED];
      } else if (isProcessingIncluded) {
        blockStatus = [ORDER_BLOCK_STATUS.NOT_BLOCKED];
      }
      filterOptionsRef.blockStatus = blockStatus;

      filterOptionsRef.processingStatusId = processingStatusId;
      filterOptionsRef.tlTaskStatusIds = selectedStatusIds;
      filterOptionsRef.fulfillmentStatusNames = selectedStatusNames;
      filterOptionsRef.statusIds = selectedOrderStatusIds;
      filterOptionsRef.orderStatusNames = selectedOrderStatusNames;
      filterOptionsRef.orderNumber = orderNumber;
      emit('apply-filter', filterOptionsRef);
    };

    const formatDate = (date) => {
      return isValidDate(date) ? formatDateMonthYear(date) : `-`;
    };

    const onOrderNumberInput = (ev) => {
      console.log(ev);
      const value = ev.target.value;

      // Removes non number characters
      const filteredValue = value.match(/\d+/g);

      orderNumber.value = filteredValue;
      const inputCmp = orderNumberField.value;
      if (inputCmp !== undefined) {
        inputCmp.$el.value = filteredValue;
      }
    };

    return {
      setOpenLoading,
      isOpenLoadingref,
      isOpenRef,
      setOpen,
      isOpenFulfillmentStatus,
      setOpenFilterByStatus,
      chevronDownOutline,
      closeOutline,
      closeCircle,
      isOpenModalDateTime,
      initializationTime,
      initializationModal,
      dayjs,
      Keyboard,
      displayCompanyName,
      updateFilterOptions,
      handleOpenModalDateTime,
      handleSendTimeChange,
      filterOptionsRef,
      invalidSelectionDate,
      applyFilter,
      resetSelections,
      applySelectedCompanies,
      formatDate,
      checkAllOrderStatusRef,
      selectedOrderStatusIds,
      selectedOrderStatusNames,
      orderNumber,
      isOpenOrderStatus,
      setOpenOrderStatus,
      handleCancelOrderStatus,
      handleSelectedOrderStatus,
      handleAllOrderStatus,
      displaySavedOrderStatuses,
      countOrderStatus,
      checkAllRef,
      orderStatuses,
      selectedStatusIds,
      selectedStatusNames,
      displaySavedStatusNames,
      fulfilmentStatusOptions,
      handleSelectedStatus,
      handleAllFulfillmentStatus,
      handleCancelFulfillmentStatus,
      onOrderNumberInput
    };
  }
};
</script>

<style scoped lang="scss">
.name {
  color: #64ae0f;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

#hidden-scroll::part(scroll) {
  overflow: hidden;
}

ion-label,
ion-select,
ion-datetime {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 14px !important;
  line-height: 18px !important;
  line-height: 22px;
}
ion-text {
  padding-top: 4px;
  display: block;
}
.searchbar-input {
  height: 100px;
}
.btn-footer {
  display: flex;
}
#content-companies {
  --padding-start: 14px;
  --padding-end: 14px;
}
.item-danger {
  color: red !important;
}
#disable-click {
  pointer-events: none;
}
.foot-btn-outline {
  --border-radius: 10px;
  --color: #00676a;
}
.foot-btn {
  --border-radius: 10px;
  --background: #00676a;
  --color: white;
}
.close-modal {
  font-size: 20px;
  color: #92949c;
  background: #989aa226;
  padding: 3px;
  border-radius: 20px;
}
.selected-count {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.5px;
  color: #eb8c31;
}
.adjust-right {
  margin-right: -10px;
}
.adjust-right-2 {
  margin-right: -6px;
}
ion-searchbar {
  --background: transparent;
  --icon-color: #00676a;
  border: 3px solid transparent;
  margin-top: -12px;
  font-size: 15px;
}
.new-search {
  border: 1.3px solid #00676a;
  border-radius: 12px;
  height: 45px;
  background: #ffffff;
  color: black;
  width: 80vw;
}
.new-search-off {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  height: 45px;
  background: #f5f5f5;
  color: #9e9e9e;
  width: 80vw;
}
.search {
  padding-right: 0px;
  padding-left: 0px;
}
.company-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  margin-top: 6vh;
}
.not-found-img {
  height: 120px;
  width: 120px;
}
.not-found-text {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.7px;
}
.try-different {
  text-align: center;
}
.item-company {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  & > ion-checkbox {
    margin-top: 12px;
  }
}
.load-search {
  --background: transparent;
}
ion-skeleton-text {
  --border-radius: 8px;
}
.search-company {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  justify-content: space-between;
}
.clear-search {
  margin-top: 3px;
  margin-right: 4px;
  font-size: 28px;
  color: #9e9e9e;
}
.item-line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.chevdown {
  margin-right: -14px;
}
.select-status {
  width: 100vw;
  height: 24px;
  margin-left: -15px;
}
.close-modal {
  font-size: 20px;
  color: #92949c;
  background: #989aa226;
  padding: 5px;
  border-radius: 20px;
}

.foot-btn-outline {
  text-transform: capitalize;
  --border-radius: 10px;
  --color: #00676a;
  height: 42px;
}
.foot-btn {
  text-transform: capitalize;
  --border-radius: 10px;
  --background: #00676a;
  --color: white;
  height: 42px;
}

.foot-btn[disabled] {
  text-transform: capitalize;
  --border-radius: 10px;
  --background: var(--Grey-500, #9e9e9e);
  --color: white;
  height: 42px;
}
.reset {
  text-transform: capitalize;
}
ion-spinner {
  position: absolute;
  left: 45%;
  z-index: 999999;
}
</style>

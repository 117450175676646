<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <div class="pa-1 pt-1">
          <ion-row class="align-center">
            <ion-col size="4" class="ion-no-padding fs-15px">
              <ion-text class="text-bold fs-3">{{ $t('orderB2b.order') }} #{{ orderDetails.id }}</ion-text>
            </ion-col>
            <ion-col class="ion-no-padding medium fs-3">
              <div class="ion-text-end">
                <ion-chip color="primary" class="ion-no-margin disable-click">
                  <ion-icon :icon="repeatOutline"></ion-icon>
                  <ion-label>{{ orderDetails.orderStatus.name }}</ion-label>
                </ion-chip>
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="mt-2 align-center">
            <ion-col size="8" class="ion-no-padding medium fs-3">
              <div class="d-flex">{{ $t('delivery_time') }}</div>

              <div class="d-flex mt-1">{{ showDeliveryDate }} {{ showDeliveryTime() || '' }}</div>
            </ion-col>

            <ion-col size="4" class="ion-no-padding fs-15px" v-if="!Paid">
              <div class="fs-3 text-primary text-end cursor-pointer" @click="chooseTime()">
                {{ $t('change_time') }}
              </div>
            </ion-col>
          </ion-row>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-item-divider style="min-height: 1px" class="bg-primary" />
    <ion-content ref="content" :scroll-events="true" @ionScroll="handleScroll($event)">
      <ion-grid class="ml-2 mr-2">
        <ion-row v-for="(item, index) in orderItemsInfoRef || []" :key="index">
          <div v-if="item.active && item.sku_id > 0 && ![-3, 10].includes(item.order_item_status_id)">
            <ion-col size="12">
              <div class="title d-flex fs-15px" style="color: black; line-height: 22px; width: 65%">
                {{ item.product_name }}
              </div>
              <div class="d-flex justify-space-between align-center">
                <div style="width: 50%">
                  <div class="medium mt-1 mb-1 fs-12px">
                    {{ priceFormatter(currencySymbol, item.sale_unit_price.toFixed(2)) }} ({{
                      item.total_qty
                    }}
                    {{ item.oom }}(s))
                  </div>
                  <div class="mt-1 mb-1">
                    <tooltip v-if="item.is_catch_weight" />
                  </div>
                  <div v-if="item.catch_weight" class="medium mt-1 mb-1 fs-12px text-italic">
                    <span class="text-weight-bold">{{ $t('weight') }}:</span>
                    {{ item.catch_weight }}
                  </div>
                  <div
                    class="fw-700"
                    color="primary"
                    @click="
                      orderDetails.orderItems[0]?.supplier.tenant.class_id != 1
                        ? null
                        : openUpdatePriceForm(item)
                    "
                  >
                    {{ priceFormatter(currencySymbol, item.is_sample ? 0 : item.total_price.toFixed(2)) }}
                  </div>
                  <div
                    v-if="item.price_is_tax_inclusive"
                    class="medium mt-1 mb-1 fs-12px"
                    style="font-style: italic"
                  >
                    {{ $t('tax_included') }}
                  </div>
                </div>

                <div class="count d-flex justify-end">
                  <div class="text-end" style="max-width: 35px">
                    <ion-button
                      fill="clear"
                      class="ion-no-padding-end"
                      color="danger"
                      size="small"
                      v-if="unPaid"
                      @click="confirmRemoveItem(index)"
                    >
                      <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
                    </ion-button>
                  </div>
                  <div v-if="item.is_order_by_weight" class="d-flex align-self-center h-input">
                    <ion-input
                      :disabled="item.is_sample && isMerchantUser"
                      :readonly="Paid"
                      v-model="item.total_weight"
                      type="number"
                      onKeyDown="javascript: return event.keyCode == 69 ? false : true"
                      inputmode="decimal"
                      class="input-weight"
                      @ionChange="onChangeTotalWeight(index, item.total_weight)"
                      @blur="onBlurTotalWeight(index, item.total_weight)"
                    />
                    <span class="ml-1 name">KG</span>
                  </div>
                  <div class="d-flex cnt-weight h-100" v-if="!item.is_order_by_weight">
                    <div class="align-self-center text-center">
                      <ion-button
                        :disabled="item.is_sample && isMerchantUser"
                        v-if="unPaid"
                        fill="clear"
                        size="small"
                        @click.stop="
                          item.total_qty == item.increment_qty
                            ? null
                            : onChangeQty(index, item.total_qty - item.increment_qty)
                        "
                      >
                        <ion-icon slot="icon-only" :icon="removeCircle"></ion-icon>
                      </ion-button>
                    </div>
                    <div size="5" class="align-self-center text-center">
                      <input v-model.number="item.total_qty" readonly type="number" class="input-weight" />
                    </div>
                    <div class="align-self-center text-center">
                      <ion-button
                        :disabled="
                          (item.is_sample && isMerchantUser) ||
                          (!isProcessing && item.total_qty >= item.origin_qty)
                        "
                        v-if="unPaid"
                        fill="clear"
                        size="small"
                        @click.stop="onChangeQty(index, item.total_qty + item.increment_qty)"
                      >
                        <ion-icon slot="icon-only" :icon="addCircle"></ion-icon>
                      </ion-button>
                    </div>
                  </div>
                </div>
              </div>
            </ion-col>
            <ion-col size="12" class="d-flex"> </ion-col>
          </div>
          <div v-else></div>
        </ion-row>
      </ion-grid>

      <div class="amount-footer mb-2">
        <ion-item-divider style="min-height: 1px" class="bg-primary" />
        <div class="py-3 px-3">
          <DeliveryInstructionArea
            @deliveryInstruction="setDeliveryInstruction"
            :instruction="orderDetails.delivery_instruction"
            :isPaid="Paid"
            :idModal="'addAndEdit'"
          />
        </div>
        <ion-item-divider style="min-height: 1px" class="bg-primary" />
        <div class="pl-3 pr-3 mt-2 mb-2" style="font-size: 12px">
          <ion-input
            ref="poNumberRef"
            :placeholder="$t('po_number_optional')"
            :disabled="Paid"
            v-if="!isMerchantUser"
            :value="orderDetails.po_number"
          />
          <ion-textarea
            rows="1"
            v-model="description"
            :placeholder="$t('note_to_driver') + ' ' + `(${$t('optional')})`"
          ></ion-textarea>
        </div>
        <ion-item-divider style="min-height: 1px" />

        <div v-if="!isAllItemsAreTaxInclusive" class="ion-padding" style="font-size: 12px">
          <ion-row class="ion-align-items-center">
            <ion-col class="ion-no-padding medium"> {{ $t('orderB2b.subTotal') }}</ion-col>
            <ion-col class="ion-no-padding ion-text-end medium">
              {{ priceFormatter(currencySymbol, subTotal) }}</ion-col
            >
          </ion-row>
          <ion-row v-if="!isAllItemsAreTaxInclusive" class="ion-align-items-center pt-1">
            <ion-col class="ion-no-padding medium"> {{ $t('orderB2b.tax') }} </ion-col>
            <ion-col class="ion-no-padding ion-text-end medium">
              {{ priceFormatter(currencySymbol, totalTax) }}
            </ion-col>
          </ion-row>
          <ion-row class="ion-align-items-center pt-1">
            <ion-col class="ion-no-padding medium"> {{ $t('discount') }} </ion-col>
            <ion-col class="ion-no-padding ion-text-end medium">
              {{ priceFormatter(currencySymbol, discount) }}
            </ion-col>
          </ion-row>
        </div>
      </div>
    </ion-content>
    <ion-item-divider style="min-height: 1px" />
    <ion-footer>
      <ion-toolbar class="ion-no-padding">
        <ion-row class="ion-align-items-center pt-2 pb-1 px-3">
          <ion-col class="ion-no-padding fs-3 fw-700 medium-9"> {{ $t('orderB2b.grandTotal') }}</ion-col>
          <ion-col class="ion-no-padding ion-text-end primary fs-3 fw-700">
            <div class="d-flex total-price">
              <div v-if="Paid" style="color: rgb(1, 105, 1)">{{ $t('paid') }} &nbsp;</div>
              <div v-if="unPaid" style="color: red">{{ $t('un_paid') }} &nbsp;</div>
              <div>{{ priceFormatter(currencySymbol, grandTotal) }}</div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row class="ion-no-padding">
          <ion-col class="ion-no-padding">
            <ion-button class="ion-no-margin fs-2" expand="full" color="danger" @click="confirmCancelOrder">{{
              $t('cancelOrder')
            }}</ion-button>
          </ion-col>
          <ion-col v-if="!Paid" class="ion-no-padding">
            <ion-button
              class="ion-no-margin fs-2"
              expand="full"
              color="primary"
              :disabled="isDisableEditBtnRef"
              @click="confirmUpdateOrder"
              >{{ $t('updateOrder') }}</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-footer>

    <ion-modal :is-open="isOpenTimeRef" @didDismiss="setOpenTime(false)">
      <delivery-time-modal
        :deliveryDate="setDeliveryDateFormat(currentDateTime.date)"
        :deliveryTime="currentDateTime.time"
        :currentCartItems="orderItemsInfoRef"
        :isNewOrder="false"
        @confirm="confirmDateTime"
        @close-modal="setOpenTime(false)"
      ></delivery-time-modal>
    </ion-modal>

    <ion-modal :is-open="isOpenPriceRef" css-class="modal-price" @didDismiss="setOpenPrice(false)">
      <update-price-modal
        @confirm="confirmDateTime"
        :product="priceOrder"
        @close-modal="setOpenPrice(false)"
      ></update-price-modal>
    </ion-modal>

    <ion-modal
      :is-open="isOpenConfirmOrderRef"
      css-class="custom-modal"
      @didDismiss="setOpenConfirmOrder(false)"
    >
      <confirm-action-modal
        :title="confirmActionLabel"
        :items="updatedItems"
        :currencySymbol="currencySymbol"
        :isCancelOrder="isCancelOrderRef"
        :oldItems="oldOrderItems"
        @confirm-update="updateOrder"
        @cancel-order="cancelOrder"
        @close-modal="dismissConfirmModal"
      ></confirm-action-modal>
    </ion-modal>

    <ion-loading
      :is-open="isOpenLoadingref"
      @didDismiss="setOpenLoading(false)"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
    >
    </ion-loading>
  </ion-page>
</template>

<script>
import Tooltip from '@/components/molecules/tooltip/Tooltip.vue';
import { cancelOrderB2b, updateOrderB2b } from '@/modules/b2b/services/graphql';
import DeliveryInstructionArea from '@/modules/sale/components/AddAndEditDeliveryInstruction/DeliveryInstructionArea.vue';
import { saleCancelOrder, saleUpdateOrder } from '@/modules/sale/services/graphql';
import { executeApolloClient } from '@/services/shared/apollo-client';
import { displayPrice, getTomorrow } from '@/services/shared/helper/helper';
import { useConfig } from '@/usecases/';
import { useDateFormatter } from '@/usecases/global';
import { getPriceByTaxConfig, priceFormatter } from '@/utils/';
import { IonInput, alertController, toastController } from '@ionic/vue';
import {
  addCircle,
  carOutline,
  closeOutline,
  removeCircle,
  removeCircleOutline,
  repeatOutline
} from 'ionicons/icons';
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import ConfirmActionModal from './ConfirmActionModal.vue';
import DeliveryTimeModal from './DeliveryTimeModal.vue';
import UpdatePriceModal from './UpdatePriceModal.vue';

export default defineComponent({
  name: 'UpdateOrderModal',
  emits: ['update-data'],
  components: {
    DeliveryTimeModal,
    UpdatePriceModal,
    Tooltip,
    IonInput,
    ConfirmActionModal,
    DeliveryInstructionArea
  },
  props: {
    orderDetails: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const { t, storage, isMerchantUser } = useConfig();
    const isOpenTimeRef = ref(false);
    const setOpenTime = (state) => (isOpenTimeRef.value = state);
    const isOpenPriceRef = ref(false);
    const setOpenPrice = (state) => (isOpenPriceRef.value = state);
    const updateOrderSuccess = ref({});
    const currentDateTime = reactive({
      date: '',
      time: ''
    });
    const { formatDateMonthYear, formatDayDate, formatDatePayload } = useDateFormatter();
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    const orderQuantity = ref(1);
    const user = ref(null);
    const items = ref([]);
    const isDisableEditBtnRef = ref(false);
    const removeItemListRef = ref([]);
    const description = ref(props.orderDetails.description);
    const priceOrder = ref(null);
    const currencySymbol = ref(null);
    const isOpenConfirmOrderRef = ref(false);
    const setOpenConfirmOrder = (state) => (isOpenConfirmOrderRef.value = state);
    const confirmActionLabel = ref(t('confirm_order_update'));
    const isCancelOrderRef = ref(false);
    const poNumberRef = ref(null);
    const content = ref(null);
    const scrollPosition = ref(0);
    const deliveryInstructionRef = ref(props.orderDetails.delivery_instruction);
    const setDeliveryInstruction = (state) => (deliveryInstructionRef.value = state);
    const orderItemsInfoRef = ref(
      props?.orderDetails?.orderItems
        ? [
            ...props.orderDetails.orderItems.map((oi) => ({
              ...oi,
              origin_qty: oi.total_qty
            }))
          ]
        : []
    );
    const oldOrderItems = props?.orderDetails?.orderItems
      ? props.orderDetails.orderItems.map((obj) => ({ ...obj }))
      : [];

    const handleGetSkuId = () => {
      for (const value of orderItemsInfoRef.value) {
        items.value.push({
          quantity: value.total_qty,
          sku_id: value.sku_id
        });
      }
    };
    const onBlurTotalWeight = (idx, newWeight) => {
      if (!newWeight) {
        newWeight = 1;
        onChangeTotalWeight(idx, newWeight);
      }
    };
    const onChangeTotalWeight = (idx, newWeight) => {
      newWeight = `${newWeight}`.slice(0, 8);
      if (+newWeight === 0) {
        isDisableEditBtnRef.value = true;
      } else {
        isDisableEditBtnRef.value = false;
        let pattern = /(\d+\.\d\d)/g;
        let regex = pattern.exec(newWeight);
        if (regex) {
          newWeight = parseFloat(regex[1]);
        }
        orderItemsInfoRef.value[idx].total_weight = newWeight;
        orderItemsInfoRef.value[idx].total_qty = newWeight;
        orderItemsInfoRef.value[idx].catch_weight = newWeight + ' Kg';
        const newPriceTotal = Number(displayPrice(newWeight * orderItemsInfoRef.value[idx].sale_unit_price));
        orderItemsInfoRef.value[idx].total_price = newPriceTotal;
        const newTax = Number(orderItemsInfoRef.value[idx].tax);
        orderItemsInfoRef.value[idx].tax = newTax;
      }
    };

    const formatDeliveryDate = (date = null) => {
      if (!date) return null;
      if (formatDatePayload(date) === getTomorrow()) return `${t('tomorrow')}, ${formatDateMonthYear(date)}`;
      return formatDayDate(date);
    };

    const onChangeQty = (index, total_qty) => {
      const item = orderItemsInfoRef.value[index];
      const newTotalWeight = total_qty * Number(item.weight);
      const newTotalPrice = total_qty * Number(item.sale_unit_price);
      const newTotalPriceTaxExclusive = getPriceByTaxConfig(
        newTotalPrice,
        item.price_is_tax_inclusive,
        item.tax_rate,
        'exclusive'
      );
      const newTax = newTotalPriceTaxExclusive * (item.tax_rate / 100);
      orderItemsInfoRef.value[index].total_qty = total_qty;
      orderItemsInfoRef.value[index].total_weight = Number(displayPrice(newTotalWeight));
      orderItemsInfoRef.value[index].total_price = Number(displayPrice(newTotalPrice));
      orderItemsInfoRef.value[index].tax = Number(displayPrice(newTax));
      items.value[index].quantity = total_qty;
    };
    const chooseTime = () => {
      Object.assign(currentDateTime, {
        date: currentDateTime.date ? currentDateTime.date : props.orderDetails.delivery_date,
        time: currentDateTime.time ? currentDateTime.time : props.orderDetails.delivery_time
      });
      setOpenTime(true);
    };

    const handleScroll = (e) => {
      scrollPosition.value = e.detail.scrollTop;
    };
    const triggerScroll = () => {
      if (scrollPosition.value <= 10) {
        content.value.$el.scrollToBottom(100);
      } else {
        content.value.$el.scrollToTop(100);
      }
    };

    const confirmDateTime = (data) => {
      Object.assign(currentDateTime, {
        date: data.deliveryDate,
        time: data.deliveryTime
      });
      setOpenTime(false);
      triggerScroll();
    };
    const dismissConfirmModal = () => {
      setOpenConfirmOrder(false);
      triggerScroll();
    };

    const openUpdatePriceForm = async (item) => {
      priceOrder.value = item;
      setOpenPrice(true);
    };

    const presentAlert = async (header, message, cancelButtonText, confirmButtonText, confirmHandler) => {
      const alert = await alertController.create({
        mode: 'ios',
        header,
        message,
        buttons: [
          {
            text: cancelButtonText,
            role: 'cancel'
          },
          ...(confirmButtonText
            ? [
                {
                  text: confirmButtonText,
                  handler: confirmHandler
                }
              ]
            : [])
        ]
      });
      await alert.present();
    };
    const confirmRemoveItem = async (index) => {
      if (orderItemsInfoRef.value.length == 1) {
        confirmCancelOrder();
      } else {
        await presentAlert(t('are_you_sure'), t('remove_mess'), t('no'), t('yes'), () => {
          let payload = {
            isDelete: true,
            product_name: orderItemsInfoRef.value[index].product_name,
            orderItemId: orderItemsInfoRef.value[index].id,
            qty: orderItemsInfoRef.value[index].total_qty,
            origin_unit_price: Number(orderItemsInfoRef.value[index].total_price),
            sale_unit_price: Number(orderItemsInfoRef.value[index].sale_unit_price),
            weight: null,
            total_weight: orderItemsInfoRef.value[index].total_weight,
            catch_weight: orderItemsInfoRef.value[index].catch_weight,
            status_note: orderItemsInfoRef.value[index].status_note,
            order_item_status_id: orderItemsInfoRef.value[index].order_item_status_id,
            is_order_by_weight: orderItemsInfoRef.value[index].is_order_by_weight
          };
          removeItemListRef.value.push(payload);
          orderItemsInfoRef.value.splice(index, 1);
        });
      }
    };

    const confirmUpdateOrder = async () => {
      // find changed items
      let hasItemChange = false;
      updatedItems.value.forEach((item) => {
        const oldItemInfo = oldOrderItems.find((obj) => item.orderItemId === obj.id);
        if (item.isDelete) hasItemChange = true;
        if (parseInt(item.qty) !== parseInt(oldItemInfo.total_qty)) hasItemChange = true;
      });
      if (isMerchantUser) {
        hasItemChange
          ? await presentAlert(t('updateOrder'), t('confirm_update_order_mess'), t('no'), t('yes'), () => {
              updateOrder();
            })
          : updateOrder();
      } else {
        // need to show dialog with reason info
        isCancelOrderRef.value = false;
        confirmActionLabel.value = t('confirm_order_update');
        isOpenConfirmOrderRef.value = hasItemChange;
        if (!hasItemChange) updateOrder();
      }
    };

    const confirmCancelOrder = async () => {
      // the confirmm diaglog is different between merchant & sale/admin
      if (isMerchantUser) {
        await presentAlert(t('cancelOrder'), t('order.cancel_order_confirm'), t('no'), t('yes'), () => {
          cancelOrder();
        });
      } else {
        // need to show dialog with reason info
        isCancelOrderRef.value = true;
        confirmActionLabel.value = t('confirm_order_cancel');
        isOpenConfirmOrderRef.value = true;
      }
    };

    const updateOrder = async (params) => {
      const simplified = orderItemsInfoRef.value.map((x) => {
        return {
          isDelete: false,
          orderItemId: x.id,
          qty: x.total_qty,
          origin_unit_price: Number(x.total_price),
          sale_unit_price: Number(x.sale_unit_price),
          weight: x.weight,
          total_weight: x.total_weight,
          catch_weight: x.catch_weight,
          status_note: x.status_note,
          order_item_status_id: x.order_item_status_id,
          is_order_by_weight: x.is_order_by_weight
        };
      });
      const orderItemsPayload = [...simplified, ...removeItemListRef.value].map((item) => {
        if (item?.product_name) delete item.product_name;
        return {
          ...item
        };
      });
      const payload = {
        orderId: props.orderDetails.id,
        orderVersion: props.orderDetails.version,
        deliveryDate: currentDateTime.date
          ? formatDatePayload(currentDateTime.date)
          : formatDatePayload(props.orderDetails.delivery_date),
        deliveryTime: currentDateTime.time ? currentDateTime.time : props.orderDetails.delivery_time,
        description: description.value,
        orderItems: orderItemsPayload,
        deliveryInstruction: deliveryInstructionRef.value,
        poNumber: poNumberRef.value ? poNumberRef.value.$el?.value : undefined,
        reason: !isMerchantUser && params?.reason ? params?.reason : undefined,
        reasonTypeId: !isMerchantUser && params?.reasonTypeId ? params?.reasonTypeId : undefined
      };

      if (!payload.deliveryDate) delete payload.deliveryDate;

      setOpenLoading(true);
      try {
        if (isMerchantUser) {
          await executeApolloClient(updateOrderB2b, payload);
        } else {
          await executeApolloClient(saleUpdateOrder, payload);
        }
        openToast(t('orderB2b.updateOrderSuccess'));
        isOpenConfirmOrderRef.value = false;
        emit('update-data');
      } catch (e) {
        await presentAlert(e.message, '', t('close'));
        isOpenConfirmOrderRef.value = false;
        emit('update-data');
      }
      setOpenLoading(false);
    };
    const cancelOrder = async (params) => {
      setOpenLoading(true);
      try {
        if (isMerchantUser) {
          await executeApolloClient(cancelOrderB2b, {
            orderId: props.orderDetails.id,
            orderVersion: props.orderDetails.version
          });
        } else {
          await executeApolloClient(saleCancelOrder, {
            orderId: props.orderDetails.id,
            orderVersion: props.orderDetails.version,
            reason: params.reason,
            reasonTypeId: params.reasonTypeId
          });
        }
        openToast(t('order.cancel_order_succeed'));
        isOpenConfirmOrderRef.value = false;
        emit('update-data');
      } catch (e) {
        await presentAlert(e.message, '', t('close'));
        isOpenConfirmOrderRef.value = false;
        emit('update-data');
      }
      setOpenLoading(false);
    };
    const openToast = async (message) => {
      const toast = await toastController.create({
        message,
        position: 'top',
        color: 'primary',
        duration: 2000
      });
      return toast.present();
    };

    const discount = computed(() => {
      let value = 0;
      if (props.orderDetails)
        orderItemsInfoRef.value.forEach((item) => {
          if (item.sku_id < 0 && ![-3, 10].includes(item.order_item_status_id))
            value += Number(item.total_price);
        });

      return displayPrice(value);
    });

    const subTotal = computed(() => {
      let total = 0;
      orderItemsInfoRef.value.forEach((orderItem) => {
        if (orderItem.active && orderItem.sku_id > 0 && ![-3, 10].includes(orderItem.order_item_status_id)) {
          total += orderItem.price_is_tax_inclusive
            ? Number(orderItem.total_price - orderItem.tax)
            : Number(orderItem.total_price);
        }
      });
      return displayPrice(total);
    });

    const totalTax = computed(() => {
      let gst = 0;
      orderItemsInfoRef.value.forEach((orderItem) => {
        if (orderItem.active && orderItem.sku_id > 0 && ![-3, 10].includes(orderItem.order_item_status_id)) {
          gst += Number(orderItem.tax);
        }
      });
      return displayPrice(gst);
    });
    const grandTotal = computed(() => {
      return displayPrice(Number(subTotal.value) + Number(totalTax.value) + Number(discount.value));
    });
    const showDeliveryDate = computed(() => {
      return formatDeliveryDate(
        currentDateTime.date ? currentDateTime.date : props.orderDetails.delivery_date
      );
    });
    const showDeliveryTime = computed(() => {
      return () => (currentDateTime.time ? currentDateTime.time : props.orderDetails.delivery_time);
    });
    const Paid = computed(() => {
      return props.orderDetails.is_prepaid;
    });
    const unPaid = computed(() => {
      return !props.orderDetails.is_prepaid;
    });
    const isProcessing = computed(() => {
      return props.orderDetails.orderStatus.name.toLowerCase() === 'processing';
    });

    const updatedItems = computed(() => {
      const simplified = orderItemsInfoRef.value.map((x) => {
        return {
          product_name: x.product_name,
          sku: x.sku,
          isDelete: false,
          orderItemId: x.id,
          qty: x.total_qty,
          origin_unit_price: Number(x.total_price),
          sale_unit_price: Number(x.sale_unit_price),
          weight: x.weight,
          total_weight: x.total_weight,
          catch_weight: x.catch_weight,
          status_note: x.status_note,
          order_item_status_id: x.order_item_status_id,
          is_order_by_weight: x.is_order_by_weight
        };
      });
      return [...simplified, ...removeItemListRef.value];
    });

    const isAllItemsAreTaxInclusive = computed(() => {
      return orderItemsInfoRef.value?.every((item) => item.price_is_tax_inclusive);
    });

    const setDeliveryDateFormat = (dateVal) => {
      return formatDatePayload(dateVal);
    };

    onMounted(async () => {
      const user = await storage.getUser();
      currencySymbol.value = user ? user.currency_symbol : '';
      await handleGetSkuId();
    });

    return {
      repeatOutline,
      removeCircle,
      addCircle,
      carOutline,
      closeOutline,
      removeCircleOutline,
      isOpenTimeRef,
      orderItemsInfoRef,
      setOpenTime,
      currentDateTime,
      updateOrderSuccess,
      orderQuantity,
      user,
      isOpenPriceRef,
      setOpenPrice,
      setOpenLoading,
      isOpenLoadingref,
      isOpenConfirmOrderRef,
      setOpenConfirmOrder,
      isDisableEditBtnRef,
      description,
      priceFormatter,
      items,
      formatDeliveryDate,
      onBlurTotalWeight,
      onChangeTotalWeight,
      confirmUpdateOrder,
      confirmCancelOrder,
      confirmRemoveItem,
      openUpdatePriceForm,
      onChangeQty,
      chooseTime,
      confirmDateTime,
      openToast,
      currencySymbol,
      discount,
      subTotal,
      totalTax,
      grandTotal,
      showDeliveryDate,
      showDeliveryTime,
      Paid,
      unPaid,
      isMerchantUser,
      updatedItems,
      poNumberRef,
      setDeliveryInstruction,
      confirmActionLabel,
      isCancelOrderRef,
      oldOrderItems,
      updateOrder,
      cancelOrder,
      setDeliveryDateFormat,
      triggerScroll,
      handleScroll,
      dismissConfirmModal,
      content,
      isProcessing,
      isAllItemsAreTaxInclusive
    };
  }
});
</script>

<style lang="scss" scoped>
$small-screen: 350px;

.fs-15px {
  line-height: 22px;
  color: #9e9e9e;
}

ion-button.count {
  --border-radius: 14px;
}

.cnt-weight {
  border: none;
  border-radius: 5px;
}
.input-weight {
  width: 100%;
  border: 0;
  text-align: center;
  border-bottom: 1px dashed var(--ion-color-primary);
  background-color: #fff;
  --padding-bottom: 30px;
  @media screen and (max-width: $small-screen) {
    width: 30px;
  }
  &:focus {
    outline: none;
  }
}
.add-weight {
  position: relative;
  max-width: 120px;
  padding-left: 20px;
  .name {
    position: absolute;
    right: 0;
  }
}
.count {
  width: 65%;
}
.total-price {
  justify-content: flex-end;
}
.h-input {
  height: 1rem;
}
</style>
